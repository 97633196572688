var HomeScript = (function($){

    var Init = function(){

        // slider
        var swiper = new Swiper('.swiper-container', {
            pagination: '.swiper-pagination',
            autoplay : 5000,
            paginationClickable: true,
            paginationBulletRender: function (index, className) {
                index = index + 1;
                var $slide = $('.js-slide-'+index);
                var title = $slide.data('title');
                var subheadline = $slide.data('subheadline');
                return '<div class="' + className + '"><h4>'+ title + '</h4><p>' + subheadline + '</p></div>';
            }
        });

        // hover map
        $('map').imageMapResize();
        $('area').hover(function() {
            $('.js-opt-eu-map').attr('src', $(this).data("hover") );
        }, function() {
            himg = $('.js-opt-eu-map').data('base');
            $('.js-opt-eu-map').attr('src',himg);
        });
        $('.js-opt-eu-country').hover(function() {
            $('.js-opt-eu-map').attr('src', $(this).data("hover") );
        }, function() {
            himg = $('.js-opt-eu-map').data('base');
            $('.js-opt-eu-map').attr('src',himg);
        });

    };
    // "public" methods
    return {
      Init : Init
    };

})(jQuery);