var NaOptMapScript = (function($){

    var Init = function(){

        // hover map
        $('map').imageMapResize();
        $('area').hover(function() {
            $('.js-opt-eu-map').attr('src', $(this).data("hover") );
        }, function() {
            himg = $('.js-opt-eu-map').data('base');
            $('.js-opt-eu-map').attr('src',himg);
        });
        $('.js-opt-eu-country').hover(function() {
            $('.js-opt-eu-map').attr('src', $(this).data("hover") );
        }, function() {
            himg = $('.js-opt-eu-map').data('base');
            $('.js-opt-eu-map').attr('src',himg);
        });

    };
    // "public" methods
    return {
      Init : Init
    };

})(jQuery);