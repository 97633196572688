var SocialCounter = (function($){

    // Facebook Shares Count
    function facebookShares($URL) {
        $.getJSON('https://graph.facebook.com/?id=' + $URL, function (fbdata) {
            console.log('FB:'+fbdata.shares);
        });
    }

    function twitterShares($URL) {
        $.getJSON('https://public.newsharecounts.com/count.json?url=' + $URL + '&callback=?', function (twitterdata) {
            console.log('TW:'+twitterdata.count);
        });
    }

    function linkedinShares($URL) {
		$.ajax({
			url: "https://www.linkedin.com/countserv/count/share?url=" + $URL + "&callback=?",
			type: "GET",
			dataType: "json",
			timeout: 2500,
			error: function(jqXHR, status, errorThrown){  
				console.log("ERROR!!!!");
			},
			success: function (linkedindata) {
				console.log('LINKE:'+linkedindata.count);
			}
		});
    }



    function googleplusShares($URL) {
		var gplusRequest = [{
			"method":"pos.plusones.get",
			"id":"p",
			"params":{
				"nolog":true,
				"id":$URL,
				"source":"widget",
				"userId":"@viewer",
				"groupId":"@self"
				},
			"jsonrpc":"2.0",
			"key":"p",
			"apiVersion":"v1"
		}];

		$.ajax({
			url: "https://clients6.google.com/rpc?key=AIzaSyCKSbrvQasunBoV16zDH9R33D88CeLr9gQ",
			type: "POST",
			data: JSON.stringify(gplusRequest),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			timeout: 2500,
			error: function(jqXHR, status, errorThrown){  
				return 0;
			},
			success: function(googleplusSharess) {
				console.log('G: '+googleplusSharess[0].result.metadata.globalCounts.count);
			}
		});
	}

    var Init = function(){
        console.log('hey counter!');
        // facebookShares('http://commence.dev/news/new-1/');
        // twitterShares('http://commence.dev/news/new-1/');
        // googleplusShares('http://commence.dev/news/new-1/');
        // linkedinShares('http://commence.dev/news/new-1/');
    };

    return {
		Init : Init
    };

})(jQuery);