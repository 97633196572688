var CommonScript = (function($){

    var Init = function(){
        // TOGGLE MOBILE MENU
        $( '.show-menu' ).click(function(){
            $('#navigation').toggleClass('is-active');
            $('.mobile-navigation-overlay').toggleClass('is-active');
        });
        // CLOSE MOBILE MENU WHEN CLICKED OUTSIDE
        $( '.mobile-navigation-overlay' ).click(function(){
            $(this).removeClass('is-active');
            $('#navigation').removeClass('is-active');
        });
        // CLOSE MOBILE MENU WHEN CLICKED ON CLOSE BUTTON
        $( '#navigation button.close' ).click(function(){
            $('.mobile-navigation-overlay').removeClass('is-active');
            $('#navigation').removeClass('is-active');
        });
        // OPEN MOBILE SUBMENUS ON CLICK
        $(document).on("click", ".submenu-opener", function() {
            $(this).closest("li").toggleClass('open');
        });
        // NEWSLETTER FORM SUBMIT
        $(".js-newsletter-form").submit(function(e) {
            $('.js-error').remove();
            if($('.js-terms').is(":checked")){
            }else{
                $(this).append('<p class="js-error policy">Please agree with the privacy policy to proceed</p>');
                return false;
            }
            var n_email = $(this).find('input[type=email]').val();
            if( n_email != '' ){
            }else{
                return false;
            }
            $.post(
                scriptParams.ajax_url,
                {
                    'action': 'newsletter_submit',
                    'data':   {'email' : n_email }
                },
                function(response){
                    obj = JSON.parse(response);
                    // console.log( obj );
                    if(obj.success === true){
                        $(".js-newsletter-form").append('<p class="js-error success">You are now subscribed to our mailing list. Thank you.</p>');
                    }
                }
            );
            return false;

        });

    };
        // "public" methods
    return {
      Init : Init
    };

})(jQuery);