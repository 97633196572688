var ResearchReportsScript = (function($){

    var Init = function(){

        $('.js-report-step').on("click",function(){
            return false;
        });
        $('.js-light-reports-form').hide();
        $('.js-full-reports-form').hide();

        var topy = $('#navi-steps').offset().top - 10;

        // Light Reports
        $('.js-light-select').on('click',function(){
            var cb_checked = $('.js-light-cb:checked');
            if( cb_checked.length === 0 ){
                alert('You must at least select one light report to continue.');
            }else{
                $('.report-selection').removeClass('js-visible');
                $('.js-report-forms').addClass('js-visible');
                var html_selection = '';
                var selection = '';
                var id_selection = '';
                cb_checked.each(function(){
                    var current_cb_label = $('.js-light-label-'+$(this).attr('id') );
                    html_selection += '<p>'+current_cb_label.html()+'</p>';
                    selection += current_cb_label.text()+', ';
                    id_selection +=  $(this).attr('id')+', ';
                });

                $('.js-report-step-1').removeClass('js-active');
                $('.js-report-step-2').addClass('js-active');
                
                $('.js-light-reports-form').show();
                
                $('.choosen_reports_html_field').html( html_selection );
                $('#fld_4904666_1').val( selection ).hide();
                $('#fld_9309071_1').val( id_selection ).hide();

            }
            window.scrollTo(0,topy);
            return false;

        });

        // Full Reports
        $('.js-full-select').on('click',function(){
            var cb_checked = $('.js-full-cb:checked');
            if( cb_checked.length === 0 ){
                alert('You must at least select one light report to continue.');
            }else{
                $('.report-selection').removeClass('js-visible');
                $('.js-report-forms').addClass('js-visible');
                var html_selection = '';
                var selection = '';
                var price = 0;
                // var id_selection = '';
                cb_checked.each(function(){
                    var current_cb_label = $('.js-full-label-'+$(this).attr('id') );
                    html_selection += '<p>'+current_cb_label.html()+'</p>';
                    selection += current_cb_label.text()+', ';
                    // id_selection +=  $(this).attr('id')+', ';
                    price = price + parseInt($(this).data('price'));
                });

                if( price != 0 ){
                    html_selection += '<p><span class="total-amount">Total Amount: '+price+' €</span></p>';
                }

                $('.js-report-step-1').removeClass('js-active');
                $('.js-report-step-2').addClass('js-active');
                
                
                $('.js-full-reports-form').show();
                
                $('.choosen_reports_html_field').html( html_selection );
                $('#fld_4904666_2').val( selection ).hide();
                // $('#fld_9309071_1').val( id_selection ).hide();

            }
            window.scrollTo(0,topy);
            return false;
        });

    };
    // "public" methods
    return {
      Init : Init
    };

})(jQuery);