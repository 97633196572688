(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        CommonScript.Init();
      }
    },
    'home': {
      init: function() {
        // JavaScript to be fired on all pages
        HomeScript.Init();
      }
    },
    'research-figures': {
      init: function() {
        // JavaScript to be fired on all pages
        ResearchFiguresScript.Init();
      }
    },
    'research-reports': {
      init: function() {
        // JavaScript to be fired on all pages
        ResearchReportsScript.Init();
      }
    },
    'na-opt-map': {
      init: function() {
        // JavaScript to be fired on all pages
        NaOptMapScript.Init();
      }
    },
    'search': {
      init: function() {
        // JavaScript to be fired on all pages
        SearchScript.Init();
      }
    }
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {

      // Fire common init JS
      UTIL.fire('common');

      firejs = $('body').data('js');
      if( firejs != null ){
        UTIL.fire(firejs);
      }

    }
  };

  $(document).ready(function(){
      UTIL.loadEvents();
  });

})(jQuery); // Fully reference jQuery after this point.

function lr_form_submit( form_data ){
  jQuery('.js-report-step-2').removeClass('js-active');
  jQuery('.js-report-step-3').addClass('js-active');
  jQuery('.js-report-forms').removeClass('js-visible');
  jQuery('.js-report-success').addClass('js-visible');
  // $("#elementID").offset().top
  window.scrollTo(0, 0);
}
