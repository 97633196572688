var SearchScript = (function($){

    var Init = function(){

        $('.js-hide').each(function(){
            $(this).hide();
        });
        $('.js-loadmore-pages').on('click',function(){
            $('.js-hide').each(function(){
                if( $(this).hasClass('js-pages') ){
                    $(this).show();
                }
            });
            $(this).hide();
            return false;
        });
        $('.js-loadmore-docs').on('click',function(){
            $('.js-hide').each(function(){
                if( $(this).hasClass('js-docs') ){
                    $(this).show();
                }
            });
            $(this).hide();
            return false;
        });
        $('.js-loadmore-posts').on('click',function(){
            $('.js-hide').each(function(){
                if( $(this).hasClass('js-posts') ){
                    $(this).show();
                }
            });
            $(this).hide();
            return false;

        });

    };
    // "public" methods
    return {
      Init : Init
    };

})(jQuery);